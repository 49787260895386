<template>
  <!--begin::Equipment Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2" v-if="false">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllEquipments"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_equipments
                  }}</template>
                  <template v-else>{{ item.equipment_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2" class="pl-0">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllEquipments"
            @keydown.tab="getAllEquipments"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Created Or Modified Date"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllEquipments"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllEquipments"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="exportEquipments"
              >
                <v-icon>mdi-file-excel-outline</v-icon>
              </v-btn>
            </template>
            <span>Export</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="6"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Equipment #</th>
            <th class="simple-table-th" width="100">Linked With</th>
            <th class="simple-table-th" width="200">Basic Details</th>
            <th class="simple-table-th" width="200">Warranty</th>
            <th class="simple-table-th" width="200">Created Time</th>
            <th class="simple-table-th" width="200">Modified Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('equipment.detail', {
                    params: { id: row.equipment_id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <Barcode
                  route="equipment.detail"
                  :barcode="row.barcode"
                  :id="row.equipment_id"
                ></Barcode>
              </td>
              <td
                class="simple-table-td"
                v-on:click.stop="
                  $router.push(
                    getDefaultRoute(getDetailRoute(row), {
                      params: { id: getDetailId(row) },
                    })
                  )
                "
              >
                {{ getDetailText(row) }} <br />
                <Barcode
                  :route="getDetailRoute(row)"
                  :barcode="getDetailBarcode(row)"
                  :id="getDetailId(row)"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="equipment-listing">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>{{ row.name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Charges: </b>
                    <template v-if="row.charges">{{
                      $accountingJS.formatMoney(row.charges)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Charges</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Serial No.: </b>
                  <template v-if="row.warranty_unique_id">{{
                    row.warranty_unique_id
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Serial No.</em></template
                  >
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Start: </b>
                  <template v-if="row.warranty_start_date">{{
                    formatDate(row.warranty_start_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Start Date</em></template
                  >
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>End: </b>
                  <template v-if="row.warranty_start_date">{{
                    formatDate(row.warranty_end_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No End Date</em></template
                  >
                </p>
                <p
                  v-if="row.warranty_end_date"
                  class="m-0 custom-nowrap-ellipsis"
                >
                  <template
                    v-if="
                      momentObject().isAfter(
                        momentObject(row.warranty_end_date)
                      )
                    "
                  >
                    <b>Expired</b>
                  </template>
                  <template v-else>
                    <b
                      >Expiring on
                      {{ formatDate(row.warranty_end_date) }} midnight.</b
                    >
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="100">
                <TableActivity
                  v-if="
                    !lodash.isEmpty(row.added_by) && row.added_by_display_name
                  "
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
              <td class="simple-table-td" width="100">
                <TableActivity
                  v-if="
                    !lodash.isEmpty(row.updated_by) &&
                    row.updated_by_display_name
                  "
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.updated_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.modified_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.updated_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="6">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no equipment at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading.sync="dataLoading"
      :showingFrom.sync="showingFrom"
      :showingTo.sync="showingTo"
      :totalRows.sync="totalRows"
      :currentPage.sync="currentPage"
      :totalPages.sync="totalPages"
    ></ListingFooter>
  </v-container>
  <!--end::Equipment Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      momentObject: moment,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.getAllEquipments();
          }
        }
      },
    },
  },
  components: {
    Barcode,
    TableActivity,
    ListingFooter,
    ListingTable,
  },
  methods: {
    getDetailText(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return "Job";
      }
      if (row.quotation_id && row.quotation_barcode) {
        return "Quotation";
      }
      if (row.invoice_id && row.invoice_barcode) {
        return "Invoice";
      }
      if (row.proposal_id && row.proposal_barcode) {
        return "Proposal";
      }
      if (row.contract_id && row.contract_barcode) {
        return "Contract";
      }
    },
    getDetailRoute(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return "job.detail";
      }
      if (row.quotation_id && row.quotation_barcode) {
        return "quotation.detail";
      }
      if (row.invoice_id && row.invoice_barcode) {
        return "invoice.detail";
      }
      if (row.proposal_id && row.proposal_barcode) {
        return "proposal.detail";
      }
      if (row.contract_id && row.contract_barcode) {
        return "contract.detail";
      }
    },
    getDetailBarcode(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return row.ticket_barcode;
      }
      if (row.quotation_id && row.quotation_barcode) {
        return row.quotation_barcode;
      }
      if (row.invoice_id && row.invoice_barcode) {
        return row.invoice_barcode;
      }
      if (row.proposal_id && row.proposal_barcode) {
        return row.proposal_barcode;
      }
      if (row.contract_id && row.contract_barcode) {
        return row.contract_barcode;
      }
    },
    getDetailId(row) {
      if (row.ticket_id && row.ticket_barcode) {
        return row.ticket_id;
      }
      if (row.quotation_id && row.quotation_barcode) {
        return row.quotation_id;
      }
      if (row.invoice_id && row.invoice_barcode) {
        return row.invoice_id;
      }
      if (row.proposal_id && row.proposal_barcode) {
        return row.proposal_id;
      }
      if (row.contract_id && row.contract_barcode) {
        return row.contract_id;
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllEquipments();
    },
    exportEquipments() {
      const _this = this;

      let filter = {
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      Object.keys(filter).forEach((key) =>
        filter[key] === undefined ? delete filter[key] : {}
      );

      const uriParams = new URLSearchParams(filter).toString();

      const exportUrl =
        process.env.VUE_APP_API_URL + "equipment/export?" + uriParams;

      window.open(exportUrl);

      return false;
    },
    getAllEquipments() {
      const _this = this;

      let filter = {
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "equipment/transaction",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getAllEquipments();
    });
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
