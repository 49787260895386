var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',[_c('v-row',[(false)?_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"disabled":_vm.dataLoading,"loading":_vm.dataLoading,"items":_vm.statusList,"hide-details":"","flat":"","solo":"","dense":"","label":"Status","color":"cyan","item-color":"cyan","item-text":"text","item-value":"value"},on:{"change":_vm.getAllEquipments},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}})],1),_c('v-list-item-action',[_c('v-chip',{attrs:{"color":item.color,"text-color":item.textcolor}},[(item.value == 'all')?[_vm._v(_vm._s(item.all_equipments))]:[_vm._v(_vm._s(item.equipment_status_count))]],2)],1)]}}],null,false,3623624143),model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1):_vm._e(),_c('v-col',{staticClass:"pl-0",attrs:{"md":"2"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","filled":"","flat":"","label":"Search","hide-details":"","solo":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('search')},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAllEquipments.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.getAllEquipments.apply(null, arguments)}]},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.search"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Created Or Modified Date","hide-details":"","solo":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading,"clearable":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedDate,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('dates')}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan","range":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"change":_vm.getAllEquipments},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"click":_vm.getAllEquipments}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Search")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"click":_vm.exportEquipments}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)]}}])},[_c('span',[_vm._v("Export")])])],1)],1)],1),_c('ListingTable',{attrs:{"columnCount":6,"dataLoading":_vm.dataLoading,"rowData":_vm.dataRows},on:{"update:dataLoading":function($event){_vm.dataLoading=$event},"update:data-loading":function($event){_vm.dataLoading=$event},"update:rowData":function($event){_vm.dataRows=$event},"update:row-data":function($event){_vm.dataRows=$event}},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th",attrs:{"width":"100"}},[_vm._v("Equipment #")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"100"}},[_vm._v("Linked With")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Basic Details")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Warranty")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Created Time")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Modified Time")])])])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',{staticClass:"custom-border-bottom custom-border-top"},[(_vm.dataRows.length > 0)?_vm._l((_vm.dataRows),function(row,index){return _c('tr',{key:index,attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                _vm.getDefaultRoute('equipment.detail', {
                  params: { id: row.equipment_id },
                })
              )}}},[_c('td',{staticClass:"simple-table-td"},[_c('Barcode',{attrs:{"route":"equipment.detail","barcode":row.barcode,"id":row.equipment_id}})],1),_c('td',{staticClass:"simple-table-td",on:{"click":function($event){$event.stopPropagation();_vm.$router.push(
                  _vm.getDefaultRoute(_vm.getDetailRoute(row), {
                    params: { id: _vm.getDetailId(row) },
                  })
                )}}},[_vm._v(" "+_vm._s(_vm.getDetailText(row))+" "),_c('br'),_c('Barcode',{attrs:{"route":_vm.getDetailRoute(row),"barcode":_vm.getDetailBarcode(row),"id":_vm.getDetailId(row)}})],1),_c('td',{staticClass:"simple-table-td"},[_c('div',{staticClass:"equipment-listing"},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Name: ")]),_vm._v(_vm._s(row.name)+" ")]),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Reference: ")]),(row.reference)?[_vm._v(_vm._s(row.reference))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Reference")])]],2),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Charges: ")]),(row.charges)?[_vm._v(_vm._s(_vm.$accountingJS.formatMoney(row.charges)))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Charges")])]],2)])]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Serial No.: ")]),(row.warranty_unique_id)?[_vm._v(_vm._s(row.warranty_unique_id))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Serial No.")])]],2),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Start: ")]),(row.warranty_start_date)?[_vm._v(_vm._s(_vm.formatDate(row.warranty_start_date)))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Start Date")])]],2),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("End: ")]),(row.warranty_start_date)?[_vm._v(_vm._s(_vm.formatDate(row.warranty_end_date)))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No End Date")])]],2),(row.warranty_end_date)?_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[(
                    _vm.momentObject().isAfter(
                      _vm.momentObject(row.warranty_end_date)
                    )
                  )?[_c('b',[_vm._v("Expired")])]:[_c('b',[_vm._v("Expiring on "+_vm._s(_vm.formatDate(row.warranty_end_date))+" midnight.")])]],2):_vm._e()]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[(
                  !_vm.lodash.isEmpty(row.added_by) && row.added_by_display_name
                )?_c('TableActivity',{attrs:{"data":row},on:{"update:data":function($event){row=$event}},scopedSlots:_vm._u([{key:"display_name",fn:function(){return [_vm._v(" "+_vm._s(row.added_by.display_name)+" ")]},proxy:true},{key:"date_time",fn:function(){return [_vm._v(" "+_vm._s(row.created_at)+" ")]},proxy:true},{key:"format_date_time",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatDateTime(row.added_at))+" ")]},proxy:true}],null,true)}):_vm._e()],1),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[(
                  !_vm.lodash.isEmpty(row.updated_by) &&
                  row.updated_by_display_name
                )?_c('TableActivity',{attrs:{"data":row},on:{"update:data":function($event){row=$event}},scopedSlots:_vm._u([{key:"display_name",fn:function(){return [_vm._v(" "+_vm._s(row.updated_by.display_name)+" ")]},proxy:true},{key:"date_time",fn:function(){return [_vm._v(" "+_vm._s(row.modified_at)+" ")]},proxy:true},{key:"format_date_time",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatDateTime(row.updated_at))+" ")]},proxy:true}],null,true)}):_vm._e()],1)])}):(!_vm.dataLoading)?_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no equipment at the moment. ")])])]):_vm._e()],2)]},proxy:true}])}),_c('ListingFooter',{attrs:{"dataLoading":_vm.dataLoading,"showingFrom":_vm.showingFrom,"showingTo":_vm.showingTo,"totalRows":_vm.totalRows,"currentPage":_vm.currentPage,"totalPages":_vm.totalPages},on:{"update:dataLoading":function($event){_vm.dataLoading=$event},"update:data-loading":function($event){_vm.dataLoading=$event},"update:showingFrom":function($event){_vm.showingFrom=$event},"update:showing-from":function($event){_vm.showingFrom=$event},"update:showingTo":function($event){_vm.showingTo=$event},"update:showing-to":function($event){_vm.showingTo=$event},"update:totalRows":function($event){_vm.totalRows=$event},"update:total-rows":function($event){_vm.totalRows=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:totalPages":function($event){_vm.totalPages=$event},"update:total-pages":function($event){_vm.totalPages=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }